import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { Button, Loader } from "..";
import TeamMemberModal from "./modal/TeamMemberModal";
import { ASSETS_URL, AgentModel } from "../../../service";
import { Fade } from "react-awesome-reveal";

type TeamMember = {
  _id: string;
  name: string;
  designation: string;
  email: string;
  phone: string;
  license: string;
  image: string;
  description?: string;
};

const MemberCard = (props: TeamMember) => {
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  return (
    <>
      <div className="w-full md:w-1/3 lg:w-1/4 px-3 mb-8">
        <Fade direction="up" triggerOnce>
          <div className="member-card bg-neutral-800 shadow-md text-center py-6 rounded-xl">
            <img
              className="w-28 h-28 rounded-full object-cover-fit mb-4 mx-auto"
              src={
                props.image === "avatar.jpg"
                  ? "/images/avatar.png"
                  : ASSETS_URL + props.image
              }
              alt={props.name}
            />
            <div>
              <h3 className="text-lg mb-2 text-white">{props.name}</h3>
              <p className="text-white text-sm mb-2">
                <span className="font-medium"></span> {props.designation}
              </p>
              {/* <p className="text-gray-600 text-sm mb-2">
                <span className="font-semibold">Email:</span> {props.email}
              </p>
              <p className="text-gray-600 text-sm mb-4">
                <span className="font-semibold">Phone:</span> {props.phone}
              </p>  */}
              <button onClick={toggleModal} className="text-cred-100">
                View Profile
              </button>
            </div>
          </div>
        </Fade>
      </div>
      <TeamMemberModal
        title="Profile"
        open={open}
        toggleModal={toggleModal}
        data={props}
      />
    </>
  );
};

const Team = () => {
  const [teamDirector, setTeamDirector] = useState<TeamMember[]>([]);
  const [teamManger, setTeamManger] = useState<TeamMember[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getTeamMembers = async () => {
      try {
        setLoading(true);
        let directorResponse = await AgentModel.getAll("director");
        let mangerResponse = await AgentModel.getAll("manger");
        let response = await AgentModel.getAll("getAgent");
        let directorData = directorResponse.data.data;
        let mangerData = mangerResponse.data.data;
        let data = response.data.data;
        setTeamDirector(directorData);
        setTeamManger(mangerData);
        setTeamMembers(data);
        console.log("print the team director", setTeamDirector)
        console.log("print the team manager", setTeamManger)
        console.log("print the team memeber", setTeamMembers)

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getTeamMembers();
  }, []);

  return (
    <div className="bg-neutral-900 py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl mb-8 text-center text-white">Our Team</h2>
        <div className="flex flex-wrap justify-center -mx-6">
          {isLoading ? (
            <Loader />
          ) : teamDirector.length > 0 ? (
            teamDirector.map((member, index) => (
              <MemberCard key={index} {...member} />
            ))
          ) : (
            <p>No Team Member</p>
          )}
        </div>

        <h2 className="text-3xl mb-8 text-center text-white">Our Agents</h2>
        <div className="flex flex-wrap justify-center -mx-6">
          {isLoading ? (
            <Loader />
          ) : teamManger.length > 0 ? (
            teamManger.map((member, index) => (
              <MemberCard key={index} {...member} />
            ))
          ) : (
            <p>No Team Member</p>
          )}
        </div>

        <div className="flex flex-wrap justify-center -mx-6">
          {isLoading ? (
            <Loader />
          ) : teamMembers.length > 0 ? (
            teamMembers.map((member, index) => (
              <MemberCard key={index} {...member} />
            ))
          ) : (
            <p>No Team Member</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;

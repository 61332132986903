import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import CountUp from "react-countup";

interface FactItemProps {
  title: string;
  number: number;
  postfix: string;
  description: string;
}

const FactItem: React.FC<FactItemProps> = ({
  title,
  number,
  postfix,
  description,
}) => (
  <div className="overflow-hidden flex flex-col">
    <p className="text-6xl text-cred-100 font-medium border-b border-neutral-900 pb-3 mb-3">
      <CountUp end={number} />
      {postfix}
    </p>
    <p className="text-lg font-semibold mb-3">{title}</p>
    <p>{description}</p>
  </div>
);

const FunFacts = () => {
  const [visibility, setVisibility] = useState(false);
  return (
    <div className="pb-10">
      <div className="container mx-auto px-6">
        <Fade
          direction="up"
          triggerOnce
          onVisibilityChange={(inView: boolean) =>
            inView && setVisibility(true)
          }
        >
          {visibility && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
              <FactItem
                title="Mortgage Agents"
                number={50}
                postfix="+"
                description="Our team of 50+ licensed and experienced mortgage agents is dedicated to providing personalized financial solutions and helping clients achieve their homeownership and refinancing goals."
              />
              <FactItem
                title="Customers"
                number={2000}
                postfix="+"
                description="We’ve proudly served over 2,000 clients, delivering tailored mortgage solutions and guiding them through their financial journeys with trust and expertise."
              />
              <FactItem
                title="Lender Partnerships"
                number={20}
                postfix="+"
                description="Delta Mortgages works with 20+ trusted lenders, offering a diverse range of mortgage products to ensure our clients receive the most competitive rates and flexible terms."
              />
            </div>
          )}
        </Fade>
      </div>
    </div>
  );
};

export default FunFacts;
